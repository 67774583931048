import { Link } from "react-router-dom";

interface ServiceCardProps {
    imageFront: string; // Image to show on the front
    imageBack: string;  // Image to show on hover (back image)
    title: string;
    description?: string;
    isPlaceOrder?: boolean;
    listItems?: any;
    pageUrl?: string;
    price?: string; // For photo editing services only, this field should be present. Otherwise, it should be absent.
    exploreBtn?: boolean;
    classes?: any; // Additional CSS classes to add to the service card container.
}

const ServiceCard = ({ imageFront, imageBack, title,classes, description, isPlaceOrder = true, listItems, pageUrl, price, exploreBtn }: ServiceCardProps) => {
    return (
        <div className={'services-box ' + classes?.join(" ")} data-aos="fade-up">
            <div className="flip-container">
                <div className="flipper">
                    <div className="front">
                        <img className="w-100 serviceCardImage" src={imageFront} alt="Front Image" />
                    </div>
                    <div className="back">
                        <img className="w-100 serviceCardImage" src={imageBack} alt="Back Image" />
                    </div>
                </div>
            </div>
            <div className="text">
                <h3>{title}</h3>
                {description && <p dangerouslySetInnerHTML={{ __html: description }}></p>}
                {listItems && listItems.length > 0 && (
                    <ul>
                        {listItems.map((item:any, index:number) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                )}
                <div className="price-per-photo">
                    {price && <><h3>${price}</h3> <span>Per Photo</span></>}
                </div>
                {isPlaceOrder && (
                    <Link style={{ width: '190px' }} className="theme-btn" to={pageUrl ? pageUrl : '/'}>
                        Place Order
                    </Link>
                )}

                {exploreBtn && (
                    <Link style={{ width: '190px' }} className="theme-btn" to={pageUrl ? pageUrl : '/'}>
                        Explore
                    </Link>
                )}
            </div>
        </div>
    );
};

export default ServiceCard;
