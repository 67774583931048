import { Carousel } from "antd";
import { useEffect, useState } from "react";

const HomeBannerSlider = () => {
  const slides = [
    { id: 1, imageUrl: "/assets/images/banner.jpeg", text: "" },
    { id: 2, imageUrl: "/assets/images/banner2.png", text: "" },
    { id: 3, imageUrl: "/assets/images/banner.jpeg", text: "" },
    { id: 4, imageUrl: "/assets/images/banner2.png", text: "" },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="banner-slider-home">
        <Carousel autoplay effect="fade" autoplaySpeed={3000} arrows={false}>
      {slides.map(slide => (
        <div key={slide.id}>
          <h3
            style={{
              height: "100vh",
              color: "#fff",
              lineHeight: "160px",
              textAlign: "center",
              background: "#364d79",
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: `url(${slide.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: 'brightness(0.6)',
              marginBottom: '0px'
            }}
          >
            {slide.text}
          </h3>
        </div>
      ))}
    </Carousel>
    </div>
  );
};

export default HomeBannerSlider;
